import { ContainedLoader } from 'components/ContainedLoader';
import { device } from 'helpers/css/responsive';
import { customizableThinScrollBarStyle } from 'helpers/css/thin-scrollbar';
import {
  DefinedGradeData,
  computeGradeECNi,
} from 'pages/correction/components/CorrectionGroupHeader/points-formatter';
import { FC } from 'react';
import styled from 'styled-components/macro';
import { Header } from '../../../components/Blocks/Header';
import { ProgressBar } from '../../../components/Blocks/ProgressBar';
import { Grid } from './Grid';
import { Row } from './Row';
import { useSpeContext } from '../../speContext';
import { useGetSpecialtyRecap } from 'modules/recap/hooks/useGetSpecialtyRecap';
import { getSpeProgressPercentage } from 'modules/recap/domain/useCases/getSpeProgressPercentage';
import { SpeQI } from 'modules/progress/detailed/spe/domain/entities/SpeQI';

interface Props {
  questions?: SpeQI[];
}

export const QiListBlock: FC<Props> = ({ questions }) => {
  const speGrade = questions?.reduce<DefinedGradeData>(
    (totalPoints, currentGroup) => {
      if (currentGroup.lastGrade === undefined) {
        return totalPoints;
      }

      const currentGroupPoints = computeGradeECNi(
        {
          grade: currentGroup.lastGrade ?? 0,
          totalCoeff: currentGroup.lastGrade ? currentGroup.gradeDenom : 1,
        },
        'QI'
      );

      totalPoints.grade += currentGroupPoints.grade;
      totalPoints.totalCoeff += currentGroupPoints.denominator;
      return totalPoints;
    },
    { grade: 0, totalCoeff: 0 }
  );
  const displayedSpeGrade =
    speGrade === undefined
      ? ''
      : speGrade.totalCoeff
      ? ((speGrade.grade / speGrade.totalCoeff) * 20).toLocaleString('fr-FR', {
          maximumFractionDigits: 2,
        }) + '/20'
      : '0/20';

  const { selectedSpe } = useSpeContext();
  const { data: speRecap } = useGetSpecialtyRecap(
    selectedSpe
      ? {
          specialty: selectedSpe.value,
        }
      : undefined
  );
  const spePercentageProgress = speRecap
    ? getSpeProgressPercentage(speRecap)
    : undefined;

  return (
    <Container>
      <ScrollContainer>
        <Header name="Questions Isolées" grade={displayedSpeGrade} />
        <ProgressBar percentage={spePercentageProgress?.qi ?? 0} />
        <GridContainer>
          {questions === undefined && <ContainedLoader />}

          {questions && (
            <Grid>
              {questions.map((question) => (
                <Row question={question} key={question.id} />
              ))}
            </Grid>
          )}
        </GridContainer>
      </ScrollContainer>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  padding: 8px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.primary.light};

  --scroll-handle-color: ${({ theme }) => theme.colors.inactive};
  --scroll-background-color: ${({ theme }) => theme.colors.primary.light};
  ${customizableThinScrollBarStyle}

  display: flex;
  flex-direction: column;

  @media ${device.desktop} {
    padding: 16px;
  }

  overflow: hidden;
`;

const GridContainer = styled.div`
  overflow: hidden;
  flex-grow: 1;
  margin-top: 4px;

  @media (min-height: 1000px) {
    margin-top: 24px;
  }
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  height: 100%;
`;
