import { PremiumLockedRessource } from 'components/PremiumLockedRessource';
import { SpeQuestionGroup } from 'modules/progress/detailed/spe/domain/entities/SpeQuestionGroup';
import { GroupType } from 'modules/shared/entities/GroupType';
import { formatGradeOutOfTwenty } from 'pages/correction/components/CorrectionGroupHeader/points-formatter';
import { useShouldShowMedian } from 'modules/user-options/hooks/useShouldShowMedian';
import { GradeData } from 'pages/correction/components/CorrectionGroupHeader/points-formatter';
import { FC, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { CheckboxCell } from './CheckboxCell';
import { SubRow } from './SubRow';
import { Tooltip } from 'components/Tooltips/Tooltip';
import { SmallArrow } from 'components/OpeningArrow/SmallArrow';

interface Props {
  group: SpeQuestionGroup;
  groupType: GroupType;
}

export const Row: FC<Props> = ({ group, groupType }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const hasBeenDone = group.lastGrade !== undefined;

  const shouldShowMedian = useShouldShowMedian(hasBeenDone);

  const toggleOpen = () =>
    hasBeenDone ? setIsOpen((oldVal) => !oldVal) : null;

  const gradeData: GradeData = {
    grade: group.lastGrade ?? null,
    totalCoeff: group.denom,
  };

  const medianData: GradeData = {
    grade: group.median,
    totalCoeff: group.denom,
  };

  const cellProps = {
    onClick: toggleOpen,
    hasBeenDone,
  };

  return (
    <>
      <Cell {...cellProps}>
        {group.collection} - {groupType}
        {group.num}
      </Cell>
      <Cell {...cellProps}>
        <PremiumLockedRessource
          ressourceName="Médiane nationale"
          isFree={group.isFree}
        >
          {shouldShowMedian && (
            <Tooltip content="Médiane nationale">
              <MedianText>{formatGradeOutOfTwenty(medianData)}</MedianText>
            </Tooltip>
          )}
        </PremiumLockedRessource>
      </Cell>
      <Cell {...cellProps}>{formatGradeOutOfTwenty(gradeData)}</Cell>
      <Cell {...cellProps}>
        <CheckboxCell group={group} groupType={groupType} />
      </Cell>
      <Cell {...cellProps}>
        {hasBeenDone && (
          <SmallArrow
            open={isOpen}
            defaultDirection="down"
            openedDirection="up"
          />
        )}
      </Cell>

      {isOpen && (
        <SubRow
          history={group?.previousTrainings ?? []}
          groupType={groupType}
        />
      )}
    </>
  );
};

const Cell = styled.div<{ hasBeenDone: boolean }>`
  ${({ theme }) => theme.typography.h4}

  background-color: ${({ theme, hasBeenDone }) =>
    hasBeenDone ? theme.colors.primary.main : theme.colors.background.paper};
  display: grid;
  place-items: center;

  ${({ hasBeenDone }) =>
    hasBeenDone &&
    css`
      cursor: pointer;
    `}
`;

const MedianText = styled.span`
  color: ${({ theme }) => theme.colors.inactive};
`;
