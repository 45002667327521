import { PremiumLockedPage } from 'components/PremiumLockedPage';
import { device } from 'helpers/css/responsive';
import { useGetSpeDetailedProgress } from 'modules/progress/detailed/spe/hooks/useGetSpeDetailedProgress';
import { CorrectionModalProvider } from 'pages/progress/GeneralProgress/QIProgress/contexts/correctionModalContext';
import { useIsUserFree } from 'modules/user-options/hooks/useIsUserPremium';
import { FC } from 'react';
import styled from 'styled-components/macro';
import { useSpeContext } from '../speContext';
import { GroupListBlock } from './GroupListBlock';
import { QiListBlock } from './QIListBlock';

interface Props {}

export const SpeBlocks: FC<Props> = () => {
  const { selectedSpe } = useSpeContext();

  const { data: sessionDetailedProgress } = useGetSpeDetailedProgress(
    selectedSpe !== undefined
      ? {
          specialty: selectedSpe.value,
        }
      : undefined
  );

  const isUserFree = useIsUserFree();

  if (isUserFree) {
    return <PremiumLockedPage />;
  }

  return (
    <BlocksContainer>
      <BlockQI>
        <CorrectionModalProvider>
          <QiListBlock questions={sessionDetailedProgress?.qis} />
        </CorrectionModalProvider>
      </BlockQI>

      <RightBlocksContainer>
        <BlockDP>
          <GroupListBlock
            name="Dossiers Progressifs"
            groupType="DP"
            groups={sessionDetailedProgress?.dps}
          />
        </BlockDP>
      </RightBlocksContainer>
    </BlocksContainer>
  );
};

const BlocksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  overflow-y: auto;
  width: 100%;

  padding: 0 var(--horizontal-padding) 16px;
  // small strip of blank space to scroll on mobile devices
  padding-right: 24px;

  @media ${device.desktop} {
    padding: 0 var(--horizontal-padding) 16px;
    gap: 16px;
  }
`;

const BlockQI = styled.div`
  flex-grow: 1;
  flex-basis: 600px;
  flex-shrink: 1;

  max-height: 100%;
`;

const RightBlocksContainer = styled.div`
  flex-grow: 1;
  flex-basis: 600px;
  flex-shrink: 1;

  --lca-block-height: 200px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  overflow: hidden;
  max-height: 120%;

  @media ${device.desktop} {
    --lca-block-height: 232px;
    max-height: 100%;
    gap: 16px;
  }
`;

const BlockDP = styled.div`
  flex-grow: 0;
  flex-shrink: 1;
  overflow: hidden;
`;
