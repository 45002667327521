import { getSessionRecapRepository } from 'modules/recap/http/respository';
import { SpecialtyRequestType } from 'modules/recap/http/types';
import { SpecialtyRecap } from '../entities/entity';

export const getSpecialtyRecap = (
  body: SpecialtyRequestType
): Promise<SpecialtyRecap> => {
  const recapRepository = getSessionRecapRepository();
  return recapRepository.getSpecialtyRecap(body);
};
