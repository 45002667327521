import { FC, LabelHTMLAttributes } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import ReactSelect, {
  components,
  DropdownIndicatorProps,
  GroupBase,
  Props,
} from 'react-select';
import { SearchIcon } from 'ui/icons/Search';
import { getSearchBarDropdownStyle, Option } from 'ui/Dropdown/dropdownStyles';

import { device } from 'helpers/css/responsive';

type IsMulti = false;

type NativeLabelProps = LabelHTMLAttributes<HTMLLabelElement>;
interface SelectProps extends Props<Option, IsMulti, GroupBase<Option>> {
  label?: string;
  placeholder?: string;
  className?: string;
  labelProps?: NativeLabelProps;
  errorMessage?: string;
}

export const SearchDropdown: FC<SelectProps> = (props) => {
  const theme = useTheme();
  const selectStyles = getSearchBarDropdownStyle(theme);
  const {
    options,
    label,
    className,
    labelProps,
    errorMessage,
    ...selectProps
  } = props;

  const DropdownIndicator: FC<DropdownIndicatorProps<Option>> = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <IconContainer>
          <SearchIcon />
        </IconContainer>{' '}
      </components.DropdownIndicator>
    );
  };

  return (
    <Container className={className}>
      <SelectContainer>
        <ReactSelect<Option>
          options={options ?? []}
          styles={selectStyles}
          components={{ DropdownIndicator: DropdownIndicator }}
          {...selectProps}
        />
      </SelectContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 70%;
  max-width: 480px;
  margin-right: 16px;
`;

const SelectContainer = styled.div`
  display: block;
  height: 36px;
  @media ${device.desktop} {
    height: 48px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 24px;
  min-width: 24px;
`;
