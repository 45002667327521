import { SpecialtyRecap } from 'modules/recap/domain/entities/entity';

export const getSpeProgress = (speRecap: SpecialtyRecap | undefined) => {
  if (speRecap === undefined) {
    return undefined;
  }

  const percent = (speRecap.numerator / speRecap.denom) * 100;

  return Math.floor(percent);
};
