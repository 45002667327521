import { DownArrow } from 'pages/home/ui/DownArrow';
import { FC } from 'react';
import ReactSelect, {
  components,
  DropdownIndicatorProps,
  GroupBase,
  Props as SelectProps,
  StylesConfig,
} from 'react-select';
import styled, { useTheme } from 'styled-components';

type IsMulti = false;

export interface SpecialtyOption {
  label: string;
  value: string;
}

interface Props
  extends SelectProps<SpecialtyOption, IsMulti, GroupBase<SpecialtyOption>> {
  className?: string;
  placeholder?: string;
}

export const SpecialtyDropdown: FC<Props> = ({ className, ...restOfProps }) => {
  const theme = useTheme();

  const DropdownIndicator: FC<DropdownIndicatorProps<SpecialtyOption>> = (
    props
  ) => {
    return (
      <components.DropdownIndicator {...props}>
        <StyledDownArrow />
      </components.DropdownIndicator>
    );
  };

  const selectStyles: StylesConfig<SpecialtyOption, IsMulti> = {
    option: (provided, state) => {
      const style = {
        ...provided,
        color: theme.colors.primary.dark,
        backgroundColor: 'transparent',
        padding: '8px',
        '&:hover': {
          backgroundColor: theme.colors.primary.light,
        },
      };
      if (state.isSelected) {
        style.backgroundColor = theme.colors.primary.main;
      }
      if (state.isFocused) {
        style.backgroundColor = theme.colors.primary.light;
      }
      return style;
    },
    indicatorsContainer: (provided, _) => {
      const style = {
        ...provided,
        color: theme.colors.primary.dark,
      };
      return style;
    },
    indicatorSeparator: (_, __) => ({}),
    control: (provided, state) => {
      const style = {
        ...provided,
        height: '100%',
        backgroundColor: theme.colors.background.paper,
        boxShadow: state.isFocused ? 'none' : 'none',
        '&:hover': {},
        paddingLeft: '5px',
        borderRadius: '8px',
        border: 'none',
        minWidth: '150px',
      };

      return style;
    },
    dropdownIndicator: (provided, state) => {
      const style = {
        ...provided,
        transition: 'all .2s ease',
        marginRight: '8px',
        marginLeft: '8px',
      };
      if (state.selectProps.menuIsOpen) {
        style.transform = 'rotate(180deg)';
      }

      return style;
    },
    menu: (provided, state) => {
      const style = {
        ...provided,
        padding: '8px',
        backgroundColor: theme.colors.background.paper,
        borderRadius: '8px',
      };
      return style;
    },
    container: (provided, _) => {
      const style = {
        ...provided,
        height: '100%',
      };
      return style;
    },
    placeholder: (provided, _) => {
      const style = {
        ...provided,
        fontStyle: 'italic',
        fontWeight: 300,
      };
      return style;
    },
    singleValue: (provided, _) => ({
      ...provided,
      color: theme.colors.primary.dark,
    }),
  };

  return (
    <Container className={className}>
      <ReactSelect<SpecialtyOption>
        components={{ DropdownIndicator: DropdownIndicator }}
        styles={selectStyles}
        {...restOfProps}
      />
    </Container>
  );
};

const Container = styled.div``;

const StyledDownArrow = styled(DownArrow)`
  width: 8px;
  height: 8px;
`;
