import { createContext, FC, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router';

interface CompassInfoContextProps {
  activeTab: string;
  setActiveTab: (activeTab: string) => void;
  activeBook: number;
  setActiveBook: (activeBook: number) => void;
  activeItem: number;
  setActiveItem: (activeItem: number) => void;
  history: Array<{ tab: string; id: number }> | [];
  setHistory: (history: { tab: string; id: number }[] | []) => void;
}

const contextDefaultValue: CompassInfoContextProps = {
  activeTab: 'books',
  setActiveTab: () => {},
  activeBook: 0,
  setActiveBook: () => {},
  activeItem: 0,
  setActiveItem: () => {},
  history: [],
  setHistory: () => {},
};

const CompassInfoContext = createContext(contextDefaultValue);

export const CompassInfoProvider: FC = ({ children }) => {
  const [activeTab, setActiveTab] = useState<string>('books');
  const [activeBook, setActiveBook] = useState<number>(0);
  const [activeItem, setActiveItem] = useState<number>(1);
  const [history, setHistory] = useState<{ tab: string; id: number }[] | []>([
    { tab: 'book', id: 1 },
  ]);

  const navigate = useNavigate();
  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const selected = queryParameters.get('selected');
    const activeBook = queryParameters.get('activeBook');
    const activeItem = queryParameters.get('activeItem');
    if (selected === 'items') {
      setActiveTab('items');
      if (activeItem) setActiveItem(parseInt(activeItem));
    } else {
      setActiveTab('books');
      if (activeBook) setActiveBook(parseInt(activeBook));
    }
  }, []);

  useEffect(() => {
    if (activeTab === 'items') {
      navigate(`/astrolabe?selected=items&activeItem=${activeItem}`);
    } else {
      navigate(`/astrolabe?selected=books&activeBook=${activeBook}`);
    }
  }, [activeTab, activeBook, activeItem, navigate]);

  return (
    <CompassInfoContext.Provider
      value={{
        activeTab,
        setActiveTab,
        activeBook,
        setActiveBook,
        activeItem,
        setActiveItem,
        history,
        setHistory,
      }}
    >
      {children}
    </CompassInfoContext.Provider>
  );
};

export const useCompassInfoContext = () => useContext(CompassInfoContext);

export const STATEMENT_ID = -1;
