import { device } from 'helpers/css/responsive';
import styled from 'styled-components/macro';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 6fr 3fr 3fr 1fr 1fr;
  row-gap: 8px;

  // Rows border radius
  & > div:nth-of-type(5n + 1) {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    padding-left: 16px;
  }
  & > div:nth-of-type(5n + 5) {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    padding-right: 16px;
  }

  & > div {
    padding: 8px 4px;

    @media ${device.desktop} {
      padding: 8px 12px;
    }
  }
`;
