import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { routes } from './constants';
import { Login } from 'pages/login/Login';
import { MainLayout } from 'components/MainLayout';
import { SignUp } from 'pages/sign-up/SignUp';
import { LostPassword } from 'pages/lost-password/LostPassword';
import { Account } from 'pages/account/Account';
// import { Contact } from 'pages/contact/Contact';
import { FlashQuestions } from 'pages/flash-questions/FlashQuestions';
import { Lessons } from 'pages/lessons/Lessons';
import { Start } from 'pages/start/Start';
import { Selection } from 'pages/selection/Selection';
import { Progress } from 'pages/progress/Progress';
import { AuthConfirmation } from 'pages/auth-confirmation';
import { ProtectedRoute } from './ProtectedRoute';
import { VerifyEmail } from 'pages/verify-email/VerifyEmail';
import { ChangePassword } from 'pages/change-password/ChangePassword';
import { NotFound404 } from 'pages/404/NotFound404';
import { Logout } from 'pages/logout/Logout';
import { ChangeEmail } from 'pages/change-email/ChangeEmail';
import { ChangePasswordConnectedUser } from 'pages/change-password-connected-user/ChangePasswordConnectedUser';
import { DeleteAccount } from 'pages/delete-account/DeleteAccount';
import { Training } from 'pages/new_training/Training';
import { PaymentSuccess } from 'pages/payment/success/PaymentSucess';
import { PaymentFailure } from 'pages/payment/failure/PaymentFailure';
import { Subscribe } from 'pages/subscribe/Subscribe';
import { TaskCorrectionPage } from 'pages/correction/TaskCorrectionPage';
import { GroupCorrectionPage } from 'pages/correction/GroupCorrectionPage';
import { ContactConfirmation } from 'pages/contact-confirmation/ContactConfirmation';
import { PremiumRoute } from './PremiumRoute';
import { StaticPage } from 'pages/StaticPage';
import { LastCorrectionPage } from 'pages/correction/LastCorrectionPage';
import { useDevMode } from 'hooks/useDevMode';
import { useThemeVariants } from 'hooks/useThemeVariants';
import { ToastContainer } from 'react-toastify';
import { Compass } from 'pages/compass/Compass';
import { NewHome } from 'pages/newHome/Home';
import { EasyDispo } from 'pages/easy-dispo/EasyDispo';
import { ConcoursBlanc } from 'pages/concours-blanc/ConcoursBlanc';
import { CCBTraining } from 'pages/concours-blanc-training/CCBTraining';

export const Router = () => {
  const { themeVariant } = useThemeVariants();
  useDevMode();

  return (
    <BrowserRouter>
      <ToastContainer theme={themeVariant === 'dark' ? 'dark' : 'light'} />
      <Routes>
        <Route path={routes.LOGIN} element={<Login />} />
        <Route path={routes.SIGN_UP} element={<SignUp />} />
        <Route path={routes.LOST_PASSWORD} element={<LostPassword />} />
        <Route path={routes.CHANGE_PASSWORD} element={<ChangePassword />} />
        <Route path={routes.VERIFY_EMAIL} element={<VerifyEmail />} />
        <Route path={routes.AUTH_CONFIRMATION} element={<AuthConfirmation />} />
        <Route path={routes.LOGOUT} element={<Logout />} />
        <Route element={<ProtectedRoute />}>
          <Route path={routes.CHANGE_EMAIL} element={<ChangeEmail />} />
          <Route
            path={routes.CHANGE_PASSWORD_CONNECTED_USER}
            element={<ChangePasswordConnectedUser />}
          />
          <Route path={routes.DELETE_ACCOUNT} element={<DeleteAccount />} />
          <Route path={routes.TRAINING} element={<Training />} />
          <Route path={routes.CCBTRAINING} element={<CCBTraining />} />
        </Route>

        <Route element={<MainLayout />}>
          <Route element={<ProtectedRoute />}>
            <Route path={routes.NEW_HOME} element={<NewHome />} />

            <Route path={routes.ACCOUNT} element={<Account />} />

            {/* <Route path={routes.CONTACT} element={<Contact />} /> */}
            <Route path={routes.EASY_DISPO} element={<EasyDispo />} />

            <Route
              path={routes.CONTACT_CONFIRMATION}
              element={<ContactConfirmation />}
            />
            <Route path={routes.CONCOURS_BLANC} element={<ConcoursBlanc />} />

            <Route path={routes.FLASH_QUESTIONS} element={<FlashQuestions />} />
            <Route path={routes.LESSONS} element={<Lessons />} />
            <Route path={routes.PROGRESS} element={<Progress />} />
            <Route path={routes.SELECTION} element={<Selection />} />
            <Route path={routes.START} element={<Start />} />
            <Route path={routes.COMPASS} element={<Compass />} />

            <Route
              path={routes.TASK_CORRECTION}
              element={<TaskCorrectionPage />}
            />
            <Route
              path={routes.GROUP_CORRECTION}
              element={<GroupCorrectionPage />}
            />
            <Route
              path={routes.LAST_CORRECTION}
              element={<LastCorrectionPage />}
            />
            <Route path={routes.PAYMENT_SUCCESS} element={<PaymentSuccess />} />
            <Route path={routes.PAYMENT_FAILURE} element={<PaymentFailure />} />
            <Route path={routes.SUBSCRIBE} element={<Subscribe />} />
            <Route path={routes.FAQ} element={<StaticPage page="FAQ" />} />
            <Route
              path={routes.RESSOURCES}
              element={<StaticPage page="Ressources" />}
            />
            {/* to make a route Premium include in Route below */}
            <Route element={<PremiumRoute />}></Route>

            <Route path="*" element={<NotFound404 />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
