import { SpecialtyOption } from 'pages/home/components/TrainingStats/SessionDropdown';
import { createContext, FC, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { updateSelectedSpe } from 'store/slices/progressSlice';

interface SpeContext {
  selectedSpe?: SpecialtyOption;
  setSelectedSpe: (newVal: SpecialtyOption) => void;
}

const contextDefaultValue: SpeContext = {
  selectedSpe: {
    label: 'toto',
    value: 'toto',
  },
  setSelectedSpe: () => {},
};

const speContext = createContext(contextDefaultValue);

export const SpeProvider: FC = ({ children }) => {
  const dispatch = useDispatch();

  const selectedSpe = useSelector(
    (state: RootState) => state.progress.selectedSpe
  );
  const setSelectedSpe = (newSpe: SpecialtyOption) => {
    dispatch(updateSelectedSpe(newSpe));
  };

  return (
    <speContext.Provider
      value={{
        selectedSpe,
        setSelectedSpe,
      }}
    >
      {children}
    </speContext.Provider>
  );
};

export const useSpeContext = () => useContext(speContext);
