import styled from 'styled-components/macro';
import { CompassInfoProvider } from './context/compassInfoContext';
import { TabSwitch } from './component/TabSwitch';
import { PageContent } from './component/PageContent';
import { BackwardsButton } from './component/BackwardsButton';
import { useIsUserPremium } from 'modules/user-options/hooks/useIsUserPremium';
import { PremiumLockedCompass } from 'components/PremiumLockecCompass/PremiumLockedCompass';
import { Layout } from 'ui/Layout/PageLayout';

export const Compass = () => {
  const { isUserPremium } = useIsUserPremium();

  return (
    <Layout>
      <CompassInfoProvider>
        <TitleContainer>
          <h1>Astrolabe</h1>
          <BackwardsButton />
        </TitleContainer>
        {isUserPremium ? (
          <>
            <TabSwitch />
            <PageContent />
          </>
        ) : (
          <PremiumLockedCompass />
        )}
      </CompassInfoProvider>
    </Layout>
  );
};

const TitleContainer = styled.div`
  display: flex;
  margin-bottom: 40px;
`;
