import { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { generalView } from 'store/slices/progressSlice';
import styled from 'styled-components/macro';
import { Switch } from 'ui/Switch';
import { useDisplayModeContext } from '../displayModeContext';

interface Props {}

export const SessionSpeSwitch: FC<Props> = () => {
  const { isSwitchOn, toggleSwitch } = useDisplayModeContext();

  const progressState = useSelector((state: RootState) => state.progress);

  if (
    progressState.view === generalView &&
    (progressState.groupType === 'DP' || progressState.groupType === 'QI')
  ) {
    return (
      <Container>
        <span>Session</span>
        <Switch checked={isSwitchOn} onChange={toggleSwitch} />
        <span>Spé</span>
      </Container>
    );
  }

  return null;
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
