import { FC } from 'react';
import styled from 'styled-components';
import { Book } from 'http/compass/types';
import { ArrowSquareOut } from '../../../../ui/icons/ArrowSquareOut';
import { SectionTitle } from 'pages/newHome/ui/Global';
import { useHomeSearchContext } from 'pages/newHome/context/homeSearchContext';
import { useNavigate } from 'react-router-dom';

interface RelatedBooksProps {
  books: Book[] | undefined;
}

export const RelatedBooks: FC<RelatedBooksProps> = ({ books }) => {
  const { setSearchedValue } = useHomeSearchContext();
  const navigate = useNavigate();

  const navigateToBooks = async (spe: string) => {
    setSearchedValue({
      label: spe,
      value: spe,
    });
    navigate(`/new-home?selected=specialties&activeSpeciality=${spe}`);
  };

  return (
    <BooksRelatedContainer>
      <StyledSectionTitle>Livre(s) ou l'item est présent</StyledSectionTitle>
      {books?.map((book) => (
        <BookContainer
          key={book.id}
          onClick={() => navigateToBooks(book.specialty)}
        >
          {book.short_title}
          <IconContainer>
            <ArrowSquareOut />
          </IconContainer>
        </BookContainer>
      ))}
    </BooksRelatedContainer>
  );
};

const BooksRelatedContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
`;

const BookContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 531px;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  border-radius: 6px;
  margin-top: 4px;
  background-color: ${({ theme }) => theme.colors.primary.lighter};
  :hover {
    background-color: ${({ theme }) => theme.colors.primary.light};
  }
`;

const IconContainer = styled.div`
  height: 20px;
  width: 20px;
  cursor: pointer;
`;

const StyledSectionTitle = styled(SectionTitle)`
  margin: 0 0 20px 0;
`;
