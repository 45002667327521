import { NewRecapItem, NewRecapSpecialty } from 'http/new-home/types';
import { useAccount } from 'pages/account/useAccount';
import {
  createContext,
  FC,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { getHomeItems, getHomeSpecialties } from 'http/new-home/api';

export type ActiveTabType = 'specialties' | 'items';

interface HomeInfoContextProps {
  activeTab: ActiveTabType;
  setActiveTab: (activeTab: ActiveTabType) => void;
  activeSpecialtyId?: string;
  setActiveSpecialtyId: (activeSpecialtyId: string) => void;
  activeSpecialty?: NewRecapSpecialty;
  setActiveSpecialty: (activeSpecialty: NewRecapSpecialty) => void;
  activeItem: NewRecapItem | undefined;
  setActiveItem: (activeItem: NewRecapItem) => void;
  activeItemId?: number;
  setActiveItemId: (activeItemId: number) => void;
  goToHome: (activeTab?: ActiveTabType) => void;
  shownPage: 'main' | 'sub';
  updatePreferredTab: (tab: ActiveTabType) => void;
  isLoading: boolean;
}

const contextDefaultValue: HomeInfoContextProps = {
  activeTab: 'specialties',
  setActiveTab: () => {},
  activeSpecialty: undefined,
  setActiveSpecialty: () => {},
  activeSpecialtyId: undefined,
  setActiveSpecialtyId: () => {},
  activeItem: undefined,
  setActiveItem: () => {},
  activeItemId: undefined,
  setActiveItemId: () => {},
  goToHome: () => {},
  shownPage: 'main',
  updatePreferredTab: () => {},
  isLoading: false,
};

const HomeInfoContext = createContext(contextDefaultValue);

export const HomeInfoProvider: FC = ({ children }) => {
  const { userOptions, updateUserOptions } = useAccount();
  const [activeTab, setActiveTab] = useState<ActiveTabType>(
    userOptions?.home_preferred_tabs ?? 'specialties'
  );
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [shownPage, setShownPage] = useState<'main' | 'sub'>('main');
  const [activeSpecialty, setActiveSpecialty] = useState<NewRecapSpecialty>();
  const [activeSpecialtyId, setActiveSpecialtyId] = useState<
    string | undefined
  >();
  const [activeItem, setActiveItem] = useState<NewRecapItem | undefined>();
  const [activeItemId, setActiveItemId] = useState<number | undefined>();

  const navigate = useNavigate();
  const location = useLocation();

  const goToHome = useCallback((tab?: ActiveTabType) => {
    setIsLoading(true);
    if (tab) {
      setActiveTab(tab);
    }
    setActiveSpecialty(undefined);
    setActiveItem(undefined);
    setActiveItemId(undefined);
    setActiveSpecialtyId(undefined);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setHasLoaded(false);
    const queryParameters = new URLSearchParams(window.location.search);
    const selected = queryParameters.get('selected');
    const searchedActiveSpecialityId = queryParameters.get('activeSpeciality');
    const searchedActiveItemId = queryParameters.get('activeItem');

    if (selected) {
      setActiveTab(selected as ActiveTabType);
    } else if (userOptions?.home_preferred_tabs) {
      setActiveTab(userOptions.home_preferred_tabs);
    }

    if (selected === 'items') {
      if (searchedActiveItemId || searchedActiveItemId === '0') {
        setActiveItemId(parseInt(searchedActiveItemId));
        setShownPage('sub');
      } else {
        setActiveItemId(undefined);
        setShownPage('main');
      }
    } else {
      if (searchedActiveSpecialityId || searchedActiveSpecialityId === '0') {
        setActiveSpecialtyId(searchedActiveSpecialityId);
        setShownPage('sub');
      } else {
        setActiveSpecialtyId(undefined);
        setShownPage('main');
      }
    }
    setHasLoaded(true);
    setIsLoading(false);
  }, [location, userOptions?.home_preferred_tabs]);

  const getItem = useCallback(
    async (id: string) => {
      setIsLoading(true);
      const results = await getHomeItems({
        search: id,
      });

      if (results && results.results?.length > 0) {
        const searchedItem = results?.results.find(
          (item) => item.item.item === parseInt(id)
        );
        if (searchedItem) {
          setActiveItem(searchedItem);
          setActiveItemId(parseInt(id));
          navigate(`/new-home?selected=items&activeItem=${activeItemId}`);
        } else {
          goToHome();
        }
      } else {
        goToHome();
      }
      setIsLoading(false);
    },
    [navigate, activeItemId, goToHome]
  );

  const getSpecialty = useCallback(
    async (trigram: string) => {
      setIsLoading(true);
      const results = await getHomeSpecialties({ search: trigram });
      if (results && results.results?.length > 0) {
        const searchedSpecialty = results?.results.find(
          (specialty) => specialty.specialty.trigram === trigram
        );
        if (searchedSpecialty) {
          setActiveSpecialty(searchedSpecialty);
          setActiveSpecialtyId(trigram);
          navigate(
            `/new-home?selected=specialties&activeSpeciality=${trigram}`
          );
        } else {
          goToHome();
        }
      } else {
        goToHome();
      }
      setIsLoading(false);
    },
    [goToHome, navigate]
  );

  useEffect(() => {
    if (!hasLoaded || isLoading) return;

    setIsLoading(true);
    if (activeTab === 'items') {
      if (activeItem === undefined) {
        if (activeItemId === undefined) {
          navigate(`/new-home?selected=items`);
          setIsLoading(false);
        } else {
          getItem(activeItemId.toString());
        }
      } else {
        navigate(`/new-home?selected=items&activeItem=${activeItemId}`);
        setIsLoading(false);
      }
    } else if (activeTab === 'specialties') {
      if (activeSpecialty === undefined) {
        if (activeSpecialtyId === undefined) {
          navigate(`/new-home?selected=specialties`);
          setIsLoading(false);
        } else {
          getSpecialty(activeSpecialtyId);
        }
      } else {
        navigate(
          `/new-home?selected=specialties&activeSpeciality=${activeSpecialtyId}`
        );
        setIsLoading(false);
      }
    }
  }, [
    hasLoaded,
    activeTab,
    activeSpecialty,
    activeItem,
    navigate,
    activeItemId,
    activeSpecialtyId,
    getItem,
    getSpecialty,
    isLoading,
  ]);

  const updatePreferredTab = (tab: ActiveTabType) => {
    updateUserOptions({
      ...userOptions,
      home_preferred_tabs: tab,
    });
    setActiveTab(tab);
  };

  return (
    <HomeInfoContext.Provider
      value={{
        activeTab,
        setActiveTab,
        activeSpecialty,
        setActiveSpecialty,
        activeItem,
        setActiveItem,
        goToHome,
        shownPage,
        updatePreferredTab,
        activeItemId,
        setActiveItemId,
        activeSpecialtyId,
        setActiveSpecialtyId,
        isLoading: isLoading || !hasLoaded,
      }}
    >
      {children}
    </HomeInfoContext.Provider>
  );
};

export const useHomeInfoContext = () => useContext(HomeInfoContext);

export const STATEMENT_ID = -1;
