import styled from 'styled-components';
import { thinScrollbarStyle } from 'helpers/css/thin-scrollbar';
import { device } from 'helpers/css/responsive';
import { useCompassInfoContext } from '../../context/compassInfoContext';
import { ArrowSquareOut } from '../../../../ui/icons/ArrowSquareOut';
import { MathJax } from 'better-react-mathjax';

import parse from 'html-react-parser';
import { Book } from 'http/compass/types';
import React from 'react';
import { useTour } from '@reactour/tour';

interface BooksInfosContainerProps {
  book: Book | null;
}

export const BookInfosContainer: React.FC<BooksInfosContainerProps> = ({
  book,
}) => {
  const { history, setActiveTab, setActiveItem, setHistory } =
    useCompassInfoContext();

  const { isOpen } = useTour();

  const navigateToItems = (itemId: number) => {
    const newHistoryItem = [...history, { tab: 'items', id: itemId }];
    setHistory(newHistoryItem);
    setActiveItem(itemId);
    setActiveTab('items');
  };

  return (
    <Container id={'book-infos-container'}>
      <TitleContainer>{parse(book?.title ?? '')}</TitleContainer>
      <InfoContainer id="book-infos-top">
        <FirstLine>
          <SubInfo>
            <Field>Edition:</Field> <InfosBook>{book?.edition}ème</InfosBook>
          </SubInfo>
          <SubInfo>
            <Field>Date de publication:</Field>{' '}
            <InfosBook>{book?.publication_date}</InfosBook>
          </SubInfo>
          <SubInfo>
            <Field>Editeur:</Field> <InfosBook>{book?.editor}</InfosBook>
          </SubInfo>
        </FirstLine>
        <AuthorContainer>
          <Field>Auteurs:</Field> <InfosBook>{book?.authors}</InfosBook>
        </AuthorContainer>
        <InfoBook>{parse(book?.infos ?? '')}</InfoBook>
      </InfoContainer>
      <ItemsRelatedToBook id="book-infos-items">
        {book?.items && book.items.length > 1 && (
          <ScrollContainer>
            <Table>
              <tbody id="books-items-container">
                {book?.items
                  .sort((a, b) => a.item - b.item)
                  .map((item, index) => {
                    if (isOpen && index < 4) return null;
                    return (
                      <Row
                        key={item.item}
                        onClick={() => {
                          navigateToItems(item.item);
                        }}
                        id={`book-infos-item-${item.item}`}
                      >
                        <ItemNumber>Item {item.item}</ItemNumber>
                        <ItemTitleContainer>
                          <ItemTitle>
                            <StyledMathJax>{item.title}</StyledMathJax>
                            <IconContainer>
                              <ArrowSquareOut />
                            </IconContainer>
                          </ItemTitle>
                        </ItemTitleContainer>
                      </Row>
                    );
                  })}
              </tbody>
            </Table>
          </ScrollContainer>
        )}
      </ItemsRelatedToBook>
    </Container>
  );
};

const Container = styled.div`
  padding-right: 32px;
  width: 86%;
`;

const TitleContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.primary.main};
  padding: 11px 32px;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 4px;
`;

const InfoContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.primary.light};
  padding: 24px 32px;
  border-radius: 8px;
  margin-bottom: 14px;
`;

const FirstLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const AuthorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  margin-bottom: 24px;
`;

const InfoBook = styled.div``;

const SubInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  margin-right: 16px;
`;

const Field = styled.div`
  margin-right: 8px;
  min-width: 56px;
  ${({ theme }) => theme.typography.textBody2}
  font-weight: 600;
`;
const InfosBook = styled.div`
  ${({ theme }) => theme.typography.textBody2};
`;

const Table = styled.table`
  border-spacing: 4px;
`;

const ItemsRelatedToBook = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;
  max-height: 585px;

  ${thinScrollbarStyle}

  @media ${device.largeTable} {
    padding-bottom: 8px;
  }
`;

const ScrollContainer = styled.div`
  border: 3px solid ${({ theme }) => theme.colors.primary.main};
  border-radius: 6px;
  overflow-y: auto;
  padding: 6px 3px;
  flex-grow: 1;
  scroll-behavior: smooth;
`;

const ItemNumber = styled.td`
  background-color: ${({ theme }) => theme.colors.primary.lighter};
  padding: 16px;
  border-radius: 8px;
  min-width: 114px;
`;

const ItemTitleContainer = styled.td`
  background-color: ${({ theme }) => theme.colors.primary.lighter};
  padding: 16px 24px;
  border-radius: 8px;
  width: 100%;
`;

const Row = styled.tr`
  gap: 4px;
  margin-bottom: 4px;
  transition: background-color 0.3s;

  &:hover {
    cursor: pointer;
    ${ItemTitleContainer}, ${ItemNumber} {
      background-color: ${({ theme }) => theme.colors.primary.light};
    }
  }
`;

const ItemTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => theme.typography.body}
`;

const IconContainer = styled.div`
  width: 20px;
  height: 20px;
`;

export const StyledMathJax = styled(MathJax)`
  display: flex;
  font-size: 20px;
`;
