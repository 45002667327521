import { useState } from 'react';
import styled from 'styled-components';
import { RelatedBooks } from './RelatedBooks';
import { RelatedKnowledges } from './RelatedKnowledges';
import { useCompassInfoContext } from '../../context/compassInfoContext';
import {
  useGetItem,
  useGetItemsOptions,
} from '../../../newHome/hooks/useGetItems';
import { SearchDropdown } from './SearchDropdown';
import { SingleValue } from 'react-select';
import { Option } from 'ui/Dropdown/dropdownStyles';

export const ItemsInfosDisplay = () => {
  const { itemsOptions } = useGetItemsOptions();
  const { activeItem, setActiveItem, history, setHistory } =
    useCompassInfoContext();
  const [searchedValue, setSearchedValue] = useState<SingleValue<Option>>({
    label: '',
    value: '0',
  });
  const { item } = useGetItem(activeItem);

  const handleSearch = (entry: SingleValue<Option>) => {
    setSearchedValue(entry);
    const newHistoryItem = [
      ...history,
      { tab: 'items', id: Number(entry?.value) },
    ];
    if (entry) {
      setActiveItem(Number(entry.value));
      setHistory(newHistoryItem);
      setSearchedValue({ label: '', value: '0' });
    }
  };

  return (
    <ItemsContainer>
      <Title>Item</Title>
      <ItemHeader>
        <SearchDropdown
          options={itemsOptions}
          onChange={handleSearch}
          value={searchedValue}
        />
        <ItemTitle>
          {item?.item} - {item?.title}
        </ItemTitle>
      </ItemHeader>
      <ItemInfosContainer>
        <RelatedKnowledges knowledges={item?.knowledges} />
        <RelatedBooks books={item?.books} />
      </ItemInfosContainer>
    </ItemsContainer>
  );
};

const ItemsContainer = styled.div`
  margin-top: 48px;
  width: 100%;
`;

const ItemHeader = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0;
`;

const Title = styled.div`
  ${({ theme }) => theme.typography.h4}
`;

const ItemInfosContainer = styled.div`
  display: flex;
  align-items: start;
  gap: 48px;
  width: 100%;
`;

const ItemTitle = styled.div`
  font-size: 25px;
  font-weight: 700;
  line-height: 24px;
  width: 80%;
  ${({ theme }) => theme.typography.boldLabel};
`;
