import { device } from 'helpers/css/responsive';
import { customizableThinScrollBarStyle } from 'helpers/css/thin-scrollbar';
import { GroupType } from 'modules/shared/entities/GroupType';
import {
  computeGradeECNi,
  DefinedGradeData,
} from 'pages/correction/components/CorrectionGroupHeader/points-formatter';
import { FC } from 'react';
import styled from 'styled-components/macro';
import { Grid } from './Grid';
import { Header } from '../../../components/Blocks/Header';
import { ProgressBar } from '../../../components/Blocks/ProgressBar';
import { Row } from './Row';
import { ContainedLoader } from 'components/ContainedLoader';
import { useSpeContext } from '../../speContext';
import { useGetSpecialtyRecap } from 'modules/recap/hooks/useGetSpecialtyRecap';
import { getSpeProgressPercentage } from 'modules/recap/domain/useCases/getSpeProgressPercentage';
import { SpeQuestionGroup } from 'modules/progress/detailed/spe/domain/entities/SpeQuestionGroup';

interface Props {
  name: string;
  groupType: GroupType;
  groups?: SpeQuestionGroup[];
}

export const GroupListBlock: FC<Props> = ({ name, groups, groupType }) => {
  const speGrade = groups?.reduce<DefinedGradeData>(
    (totalPoints, currentGroup) => {
      if (currentGroup.lastGrade === undefined) {
        return totalPoints;
      }

      const currentGroupPoints = computeGradeECNi(
        {
          grade: currentGroup.lastGrade ?? 0,
          totalCoeff: currentGroup.lastGrade ? currentGroup.denom : 1,
        },
        groupType
      );

      totalPoints.grade += currentGroupPoints.grade;
      totalPoints.totalCoeff += currentGroupPoints.denominator;
      return totalPoints;
    },
    { grade: 0, totalCoeff: 0 }
  );
  const displayedSpeGrade =
    speGrade === undefined
      ? ''
      : speGrade.totalCoeff
      ? ((speGrade.grade / speGrade.totalCoeff) * 20).toLocaleString('fr-FR', {
          maximumFractionDigits: 2,
        }) + '/20'
      : '0/20';

  const { selectedSpe } = useSpeContext();
  const { data: speRecap } = useGetSpecialtyRecap(
    selectedSpe
      ? {
          specialty: selectedSpe.value,
        }
      : undefined
  );
  const spePercentageProgress = speRecap
    ? getSpeProgressPercentage(speRecap)
    : undefined;

  return (
    <Container>
      <ScrollContainer>
        <Header name={name} grade={displayedSpeGrade} />
        <ProgressBar percentage={spePercentageProgress?.dp ?? 0} />
        <GridContainer>
          {groups === undefined && <ContainedLoader />}

          {groups && (
            <Grid>
              {groups.map((group) => (
                <Row group={group} groupType={groupType} key={group.id} />
              ))}
            </Grid>
          )}
        </GridContainer>
      </ScrollContainer>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  padding: 8px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.primary.light};

  --scroll-handle-color: ${({ theme }) => theme.colors.inactive};
  --scroll-background-color: ${({ theme }) => theme.colors.primary.light};
  ${customizableThinScrollBarStyle}

  display: flex;
  flex-direction: column;

  @media ${device.desktop} {
    padding: 16px;
  }

  overflow: hidden;
`;

const GridContainer = styled.div`
  overflow: hidden;
  flex-grow: 1;
  margin-top: 4px;

  @media (min-height: 1000px) {
    margin-top: 24px;
  }
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  height: 100%;
`;
