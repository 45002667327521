import styled from 'styled-components/macro';
import { PageContent } from './components/PageContent';
import { DisplayOptionsMenu } from './ui/DisplayOptionsMenu';
import { HomeInfoProvider } from './context/homeInfoContext';
import { TopPageContent } from './components/TopPageContent';
import { HomeItemsFiltersProvider } from './context/homeItemsFiltersContext';
import { HomeSpecialiesFiltersProvider } from './context/homeSpecialtiesFiltersContext';
import { HomeSearchProvider } from './context/homeSearchContext';

export const NewHome = () => {
  return (
    <Layout>
      <HomeInfoProvider>
        <TitleContainer>
          <h1>Accueil</h1>
          <DisplayOptionsMenu />
        </TitleContainer>
        <HomeItemsFiltersProvider>
          <HomeSpecialiesFiltersProvider>
            <HomeSearchProvider>
              <TopPageContent />
              <PageContent />
            </HomeSearchProvider>
          </HomeSpecialiesFiltersProvider>
        </HomeItemsFiltersProvider>
      </HomeInfoProvider>
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
  padding: 48px 32px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 2rem;
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
