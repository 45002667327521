import { device } from 'helpers/css/responsive';
import { FC } from 'react';
import styled from 'styled-components/macro';
import { SpeBlocks } from './SpeBlocks';
import { SpeProvider } from './speContext';
import { SpeProgress } from './SpeProgress/SpeProgress';

interface Props {}

export const DetailBySpe: FC<Props> = () => {
  return (
    <Container>
      <SpeProvider>
        <SpeProgress />
        <SpeBlocks />
      </SpeProvider>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  margin-top: 0px;
  overflow: hidden;

  // TO DELETE
  height: 100%;

  @media ${device.desktop} {
    margin-top: 8px;
  }
`;
