import { FC } from 'react';
import styled from 'styled-components';
import { useCompassInfoContext } from '../../context/compassInfoContext';
import { Option } from 'http/compass/types';

interface ScrollElementProps {
  option: Option;
  handleClick: (value: number) => void;
}

export const ScrollELement: FC<ScrollElementProps> = ({
  option,
  handleClick,
}) => {
  const { activeBook } = useCompassInfoContext();

  return (
    <SpecialityContainer
      isActive={activeBook === option.value}
      onClick={() => handleClick(option.value)}
      id={`astrolabe-book-${option.value}`}
    >
      {option.label}
    </SpecialityContainer>
  );
};

const SpecialityContainer = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  gap: 16px;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.primary.main : theme.colors.primary.lighter};

  padding: 12px 23px;
  border-radius: 8px;
  &:hover {
    cursor: pointer;
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.colors.primary.main : theme.colors.primary.light};
  }
`;
