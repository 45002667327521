import styled from 'styled-components';
import { FC } from 'react';
import { device } from 'helpers/css/responsive';
import { thinScrollbarStyle } from 'helpers/css/thin-scrollbar';
import { useCompassInfoContext } from '../../context/compassInfoContext';
import { Option } from 'http/compass/types';
import { ScrollELement } from './ScrollElement';

interface ScrollSpeContainerProps {
  filteredOptions: Option[];
}

export const ScrollSpeContainer: FC<ScrollSpeContainerProps> = ({
  filteredOptions,
}) => {
  const { setActiveBook, history, setHistory } = useCompassInfoContext();

  const handleClick = (bookId: number) => {
    const newHistoryItem = [...history, { tab: 'books', id: bookId }];
    setActiveBook(bookId);
    setHistory(newHistoryItem);
  };

  return (
    <Container>
      <ScrollContainer>
        {filteredOptions && filteredOptions.length > 0 ? (
          filteredOptions.map((option, index) => (
            <ScrollELement
              key={index}
              option={option}
              handleClick={handleClick}
            />
          ))
        ) : (
          <NoResultsContainer>
            Aucun résultat ne correspond à votre recherche, essayez de retirer
            des items du filtre ou de rechercher avec de nouveaux mots clés
          </NoResultsContainer>
        )}
      </ScrollContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  max-height: 585px;

  ${thinScrollbarStyle}

  @media ${device.largeTable} {
    padding-bottom: 8px;
  }
`;

const ScrollContainer = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.compass.light};
  border-radius: 6px;
  overflow-y: auto;
  padding: 6px 3px;
  flex-grow: 1;
`;

const NoResultsContainer = styled.div`
  padding: 16px;
  text-align: center;
`;
