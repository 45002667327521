import { FC, ImgHTMLAttributes } from 'react';
import { getSpeIcon } from './utils';

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  trigram: string;
}

export const SpecialtyIcon: FC<Props> = ({ trigram, ...props }) => {
  return <img {...props} src={getSpeIcon(trigram) || ''} alt={trigram} />;
};
