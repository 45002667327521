import { useCompassInfoContext } from '../context/compassInfoContext';
import styled from 'styled-components/macro';
import React, { useEffect } from 'react';
import { useTour } from '@reactour/tour';

export const TabSwitch = () => {
  const { setActiveTab, activeTab, setActiveBook, setActiveItem } =
    useCompassInfoContext();
  const { currentStep, isOpen, setCurrentStep } = useTour();

  useEffect(() => {
    if (isOpen) {
      if (currentStep >= 1 && currentStep <= 4) {
        setActiveTab('books');
        setActiveBook(65);
      } else if (currentStep === 4 && activeTab === 'items') {
        setCurrentStep(5);
      } else if (currentStep === 5 && activeTab === 'books') {
        setActiveTab('items');
        setActiveItem(226);
      }
    }
  }, [
    activeTab,
    currentStep,
    isOpen,
    setActiveBook,
    setActiveItem,
    setActiveTab,
    setCurrentStep,
  ]);

  useEffect(() => {
    if (isOpen) {
      if (currentStep === 4 && activeTab === 'items') {
        setCurrentStep(5);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const handleClick = (clickedTab: 'books' | 'items') => {
    if (clickedTab !== activeTab) {
      setActiveTab(clickedTab);
    }
  };

  return (
    <SwitchContainer id="astrolabe-switcher">
      <SwitchButton
        isActive={activeTab === 'books'}
        onClick={() => handleClick('books')}
      >
        Livres
      </SwitchButton>
      <SwitchButton
        isActive={activeTab === 'items'}
        onClick={() => handleClick('items')}
      >
        Items
      </SwitchButton>
    </SwitchContainer>
  );
};

const SwitchContainer = styled.div`
  display: flex;
`;

const SwitchButton = styled.div<{ isActive: boolean }>`
  border-radius: 6px;
  padding: 16px 78px;
  margin-right: 32px;
  gap: 16px;
  background-color: ${({ isActive }) =>
    isActive
      ? ({ theme }) => theme.colors.primary.main
      : ({ theme }) => theme.colors.primary.lighter};
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${({ isActive }) =>
      isActive
        ? ({ theme }) => theme.colors.primary.main
        : ({ theme }) => theme.colors.primary.light};
  }
`;
