import { Book } from 'http/compass/types';
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';
import { IconContainer } from 'ui/icons/IconContainer';
import { ArrowRight } from 'ui/icons/Arrows/ArrowRight';

export interface TabData {
  title: string;
  id: number;
  content: React.ReactNode;
}

interface TabCardsProps {
  tabs: Book[];
  width?: string;
  setSelectedBookId: Dispatch<SetStateAction<number>>;
  selectedBookId: number;
}

export const TabCards: FC<TabCardsProps> = ({
  tabs,
  width,
  setSelectedBookId,
  selectedBookId,
}) => {
  const [displayBookInfo, setDisplayBookInfo] = useState<boolean>(false);

  const book = tabs.find((tab) => tab.id === selectedBookId);

  return (
    <TabContainer $hasWidth={width}>
      <TabListContainer>
        <TabList>
          {tabs.map((tab) => (
            <TabItem
              key={tab.id}
              $tabCount={tabs.length}
              active={tab.id === selectedBookId}
              onClick={() => {
                setSelectedBookId(tab.id);
              }}
            >
              <h5>{tab.short_title}</h5>
            </TabItem>
          ))}
        </TabList>
      </TabListContainer>
      <TabContent>
        <InfoContainer>
          {displayBookInfo ? (
            <InfoContent>
              <SubInfo>
                <Field>Titre complet:</Field>{' '}
                <InfosBook>{book?.title}</InfosBook>
              </SubInfo>
              <SubInfo>
                <Field>Edition:</Field>{' '}
                <InfosBook>{book?.edition}ème</InfosBook>
              </SubInfo>
              <SubInfo>
                <Field>Date de publication:</Field>{' '}
                <InfosBook>{book?.publication_date}</InfosBook>
              </SubInfo>
              <SubInfo>
                <Field>Editeur:</Field> <InfosBook>{book?.editor}</InfosBook>
              </SubInfo>
              <AuthorContainer>
                <Field>Auteurs:</Field> <InfosBook>{book?.authors}</InfosBook>
              </AuthorContainer>
            </InfoContent>
          ) : (
            // Here we receive Tiny MCE instead of image to fix by back-end
            // The height problem will be fixed when we replace this by a real image
            <InfoBook>{parse(book?.infos ?? '')}</InfoBook>
          )}
          <SwitchButton onClick={() => setDisplayBookInfo(!displayBookInfo)}>
            <SwitchLabel>
              {displayBookInfo ? 'Aperçu du livre' : 'Information du livre'}{' '}
            </SwitchLabel>
            <IconContainer>
              <ArrowRight />
            </IconContainer>
          </SwitchButton>
        </InfoContainer>
      </TabContent>
    </TabContainer>
  );
};

const TabContainer = styled.div<{ $hasWidth?: string }>`
  display: flex;
  flex-direction: column;
  width: ${({ $hasWidth }) => ($hasWidth ? $hasWidth : '100%')};
  height: auto;
`;

const TabList = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
`;

const TabItem = styled.li<{ active: boolean; $tabCount: number }>`
  padding: 10px 15px;
  cursor: pointer;
  background-color: ${({ theme, active }) =>
    active ? theme.colors.primary.light : theme.colors.primary.lighter};
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  width: ${({ $tabCount }) => `calc(100% / ${$tabCount})`};
  text-align: center;

  &:hover {
    background-color: ${({ theme, active }) =>
      active ? theme.colors.primary.light : theme.colors.primary.main};
  }

  h5 {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const TabContent = styled.div`
  padding: 2rem;
  background-color: ${({ theme }) => theme.colors.primary.light};
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
`;

const TabListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-radius: 12px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.primary.light};
  border-radius: 8px;
  gap: 0.5rem;
  justify-content: space-between;
  height: 100%;
`;

const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const AuthorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const InfoBook = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }
`;

const SubInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
`;

const Field = styled.div`
  margin-right: 8px;
  min-width: 56px;
  ${({ theme }) => theme.typography.textBody2}
  font-weight: 600;
`;
const InfosBook = styled.div`
  ${({ theme }) => theme.typography.textBody2};
`;

const SwitchButton = styled.div`
  display: flex;
  ${({ theme }) => theme.typography.small};

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const SwitchLabel = styled.div`
  margin-right: 8px;
  font-size: 0.875rem;
`;
