import { FC } from 'react';
import { MenuIconProps } from './types';

export const MenuHome: FC<MenuIconProps> = ({ isFilled }) => {
  return (
    <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.686 21c1.569 0 1.961-1.465 1.961-2.198v-9.89H21L11.196 1 1 8.912h2.353v9.89C3.353 20.56 4.66 21 5.313 21h2.942v-5.934h5.49V21h2.941Z"
        stroke="currentColor"
        strokeWidth={isFilled ? '2' : '1'}
        strokeLinejoin="round"
        {...(isFilled ? { fill: 'currentColor' } : {})}
      />
    </svg>
  );
};
