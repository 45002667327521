import { FC } from 'react';
import { Chip } from './Chip';
import { Item as ItemType } from 'types/item.type';
import { Tooltip } from 'components/Tooltips/Tooltip';

interface Props {
  item: ItemType;
}

export const Item: FC<Props> = ({ item }) => {
  return (
    <Tooltip
      content={item.title}
      arrow={false}
      key={item.item}
      placement="bottom"
      tooltipType={'simple'}
    >
      <Chip>{item.item}</Chip>
    </Tooltip>
  );
};
