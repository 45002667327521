import { ProgressBarStat } from 'components/StatsElements/ProgressBarStats';
import { FC, useEffect } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { getSpeProgress } from '../helpers/getSpeProgress';
import { useSpeContext } from '../speContext';
import { useGetSpecialtyRecap } from 'modules/recap/hooks/useGetSpecialtyRecap';
import { useGetSpecialitiesListSorted } from 'http/selection/specialities-list/connectors';
import { SpecialtyDropdown } from 'pages/home/components/TrainingStats/SpecialtyDropdown';

interface Props {}

export const SpeProgress: FC<Props> = () => {
  const theme = useTheme();

  const { selectedSpe, setSelectedSpe } = useSpeContext();

  const specialties = useGetSpecialitiesListSorted();
  useEffect(() => {
    if (specialties.data?.options) {
      setSelectedSpe(specialties.data.options[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialties.data?.options]);

  const { data: speRecap } = useGetSpecialtyRecap(
    selectedSpe
      ? {
          specialty: selectedSpe?.value,
        }
      : undefined
  );

  const speProgress = getSpeProgress(speRecap);

  return (
    <Container>
      <SpeDropdownContainer>
        <SpecialtyDropdown
          options={specialties.data?.options}
          value={selectedSpe}
          onChange={(newValue) => (newValue ? setSelectedSpe(newValue) : null)}
        />
      </SpeDropdownContainer>

      <ProgressContainer>
        <h3>{speProgress !== undefined && `${speProgress}%`}</h3>
        <BarContainer>
          <ProgressBarStat
            fillingColor={theme.colors.primary.dark}
            backgroundFillingColor={theme.colors.primary.main}
            percentage={speProgress}
            height={16}
          />
        </BarContainer>
      </ProgressContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  width: 100%;

  padding: 0 var(--horizontal-padding);
`;

const SpeDropdownContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  width: 100%;
`;

const BarContainer = styled.div`
  flex-grow: 1;
`;
