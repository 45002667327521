import { SpecialtyRecap } from '../entities/entity';
import { SpeProgressPercentage } from '../entities/SpeProgressPercentage';

export const getSpeProgressPercentage = (
  sessionRecap: SpecialtyRecap
): SpeProgressPercentage => {
  const qiPercentage = (sessionRecap.doneQi / sessionRecap.totalQi) * 100;
  const dpPercentage = (sessionRecap.doneDp / sessionRecap.totalDp) * 100;

  return {
    qi: qiPercentage,
    dp: dpPercentage,
  };
};
