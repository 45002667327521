import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'ui/Button';
import { CollectionTitle } from './SpecialtyProgressCard';
import { popSlideInAnimation } from 'helpers/css/animations/popSlideIn';
import { PastCorrectionButton } from 'pages/progress/GeneralProgress/components/PastCorrectionButton';
import { useAccount } from 'pages/account/useAccount';
import { routes } from 'routing/constants';
import { useNavigate } from 'react-router-dom';
import { QuestionGroup } from 'modules/progress/detailed/shared/entities/QuestionGroup';
import { QI } from 'modules/progress/detailed/shared/entities/QI';
import { FormattedDate } from 'pages/selection/components/FormattedDate';
import { useCanAddToCart } from 'pages/selection/hooks/useCanAddToCart';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { toggleItemSelection as toggleItemSelectionDP } from 'store/slices/dpTableSlice';
import { adaptGroupToSelectionGroupResult } from 'pages/progress/DetailBySpe/SpeBlocks/GroupListBlock/Row/CheckboxCell/CheckboxCell';
import { SpeQuestionGroup } from 'modules/progress/detailed/spe/domain/entities/SpeQuestionGroup';
import { toggleItemSelection as toggleItemSelectionQI } from 'store/slices/qiTableSlice';
import { adaptQIToSelectionQIResult } from 'pages/progress/DetailBySpe/SpeBlocks/QIListBlock/Row/CheckboxCell/CheckboxCell';
import { SpeQI } from 'modules/progress/detailed/spe/domain/entities/SpeQI';
import { AddIcon } from 'ui/icons/AddIcon';
import { RemoveIcon } from 'ui/icons/RemoveIcon';
import { AddToCartSuccessToast } from 'pages/progress/GeneralProgress/components/AddToCartSuccessToast';
import { toast } from 'react-toastify';
import Tippy from '@tippyjs/react';
import { SmallArrow } from 'components/OpeningArrow/SmallArrow';
import { device } from 'helpers/css/responsive';

interface Props {
  question: QuestionGroup | QI;
  type: 'QI' | 'DP';
  collection: string;
}

export const CollectionRow: React.FC<Props> = ({
  question,
  type,
  collection,
}) => {
  const [open, setOpen] = React.useState(false);
  const { userOptions } = useAccount();
  const navigate = useNavigate();
  const canAddToCart = useCanAddToCart();
  const dispatch = useDispatch();

  const selectedDPItems = useSelector(
    (state: RootState) => state.dpTable.selectedItemsInfos
  );
  const selectedQIItems = useSelector(
    (state: RootState) => state.qiTable.selectedItemsInfos
  );

  const [isSelected, setIsSelected] = useState<boolean>(false);

  useEffect(() => {
    const selectedItems = type === 'DP' ? selectedDPItems : selectedQIItems;
    setIsSelected(selectedItems.map((item) => item.id).includes(question.id));
  }, [question, selectedDPItems, selectedQIItems, type]);

  const toggleItem = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    toggleSelection();
  };

  const hasTrained = useMemo(() => {
    return question.previousTrainings.length > 0;
  }, [question]);

  const goToCorrection = (id: number) => {
    if (id) {
      navigate({
        pathname: routes.GROUP_CORRECTION,
        search: `?id=${id}`,
      });
    }
  };

  const toggleSelection = () => {
    if (type === 'DP') {
      const toAdd = {
        short: (question as QuestionGroup).number_of_questions >= 13 ? 0 : 1,
        long: (question as QuestionGroup).number_of_questions >= 13 ? 1 : 0,
      };
      if (!isSelected) {
        const canAdd = canAddToCart(type, 1, toAdd);
        if (!canAdd) return;
      }
      dispatch(
        toggleItemSelectionDP(
          adaptGroupToSelectionGroupResult(
            { ...question, collection } as SpeQuestionGroup,
            type
          )
        )
      );
      if (isSelected) {
        toast.success('Le DP a bien été retiré de la sélection');
      } else {
        toast.success(
          <AddToCartSuccessToast name="DP" cartType="DPQI" multiple={false} />
        );
      }
    } else {
      if (!isSelected) {
        const canAdd = canAddToCart('QI', 1);
        if (!canAdd) return;
      }
      dispatch(
        toggleItemSelectionQI(
          adaptQIToSelectionQIResult({ ...question, collection } as SpeQI)
        )
      );
      if (isSelected) {
        toast.success('Le QI a bien été retiré de la sélection');
      } else {
        toast.success(
          <AddToCartSuccessToast name="QI" cartType="DPQI" multiple={false} />
        );
      }
    }
  };

  return (
    <Container>
      <QuestionRow
        onClick={() => {
          hasTrained && setOpen((prev) => !prev);
        }}
        $hasTrained={hasTrained}
      >
        <GridContainer>
          <GridItem>
            <SmallArrow
              open={open}
              $hasTrained={hasTrained}
              defaultDirection="right"
              openedDirection="down"
            />
          </GridItem>
          <GridItem>
            <CollectionTitle fontWeight="400">
              {type === 'DP' ? 'DP' : 'QI n°'}
              {question.num}
            </CollectionTitle>
          </GridItem>
          <GridItem>
            <Grade grey blur={!userOptions?.home_see_grades}>
              {type === 'DP' ? (
                <Tippy content={'Médiane nationale'}>
                  <span>
                    {(question as QuestionGroup).median !== null
                      ? Math.round((question as QuestionGroup).median * 100) /
                        100
                      : '--'}
                    /20
                  </span>
                </Tippy>
              ) : (
                ''
              )}
            </Grade>
          </GridItem>
          <GridItem>
            <Grade blur={!userOptions?.home_see_grades}>
              {type === 'DP'
                ? `${
                    question.lastGrade || question.lastGrade === 0
                      ? Math.round(question.lastGrade * 100) / 100
                      : '--'
                  }/20`
                : `${
                    question.lastGrade || question.lastGrade === 0
                      ? Math.round(question.lastGrade * 100) / 100
                      : '--'
                  }/1`}
            </Grade>
          </GridItem>
          <GridItem $span={2}>
            <CustomButton variant="outlined" small onClick={toggleItem}>
              {isSelected ? 'Retirer ' : 'Ajouter'}
              <Icon>{isSelected ? <RemoveIcon /> : <AddIcon />}</Icon>
            </CustomButton>
          </GridItem>
        </GridContainer>
      </QuestionRow>

      {open && (
        <HistoricRow $hasTrained={hasTrained}>
          <HistoricTitle>Historique des copies</HistoricTitle>
          {question.previousTrainings.map((h) => (
            <GridContainer key={h.id}>
              <GridItem />
              <GridItem>
                <HistoricLabel>
                  <FormattedDate date={h.date} />
                </HistoricLabel>
              </GridItem>
              <GridItem />

              <GridItem>
                <HistoricLabel>
                  {type === 'DP'
                    ? `${h.grade !== null ? h.grade : '--'}/20`
                    : `${h.grade !== null ? h.grade : '--'}/1`}
                </HistoricLabel>
              </GridItem>
              <GridItem $span={2}>
                <PastCorrectionButton
                  onClick={() => goToCorrection(h.groupCorrectionId)}
                />
              </GridItem>
            </GridContainer>
          ))}
        </HistoricRow>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1rem;
  align-items: center;
  width: 100%;
  padding: 0.5rem;

  @media ${device.mobile} {
    gap: 0.5rem;
    padding: 0.5rem 0;
  }
`;

const GridItem = styled.div<{ $span?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  grid-column: span ${(props) => props.$span || 1};
`;

const QuestionRow = styled.div<{ $hasTrained: boolean }>`
  background-color: ${({ $hasTrained, theme }) =>
    $hasTrained ? theme.colors.primary.main : 'white'};
  border-radius: 8px;
  cursor: ${(props) => (props.$hasTrained ? 'pointer' : 'default')};
  padding: 0.5rem 1rem;
  width: 100%;

  @media ${device.mobile} {
    padding: 0.5rem 0;
  }
`;

const HistoricRow = styled.div<{ $hasTrained: boolean }>`
  background-color: ${({ theme }) => theme.colors.primary.main};
  border-radius: 8px;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  ${popSlideInAnimation}

  @media ${device.mobile} {
    padding: 0.5rem 0;
  }
`;

const HistoricTitle = styled.h4`
  font-size: 1rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.primary.dark};
  margin-bottom: 0.5rem;
`;

const HistoricLabel = styled.span`
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.primary.dark};
`;

const Grade = styled.span<{ grey?: boolean; blur?: boolean }>`
  color: ${(props) => (props.grey ? '#9E9E9E' : '#000')};
  font-weight: 400;
  font-size: 1rem;
  filter: ${(props) => (props.blur ? 'blur(5px)' : 'none')};
`;

const CustomButton = styled(Button)`
  border-radius: 12px !important;
  pointer-events: all;
  gap: 0.5rem;

  & svg path {
    transition: stroke 0.3s;
  }

  &:hover svg path {
    stroke: white;
  }

  @media ${device.mobile} {
    padding: 0;
  }
`;

const Icon = styled.div`
  width: 12px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: black;
  padding: 0 !important;
  @media ${device.mobile} {
    width: 12px;
    height: 12px;
  }
`;

export default CollectionRow;
