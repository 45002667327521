import { reportErrorToSentry } from 'helpers/application/reportErrorToSentry';

import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { getSpecialtyRecap } from '../domain/useCases/getSpecialtyRecap';
import { SpecialtyRequestType } from '../http/types';
import { MS_IN_AN_HOUR } from 'constants/date';

export const useGetSpecialtyRecap = (
  body: SpecialtyRequestType | undefined
) => {
  return useQuery(['specialtyRecap', body], () => getSpecialtyRecap(body!), {
    onError: (e) => {
      toast.error(
        "Nous n'avons pas réussi à récupérer vos données. Veuillez réessayer plus tard."
      );
      reportErrorToSentry(e);
    },
    cacheTime: MS_IN_AN_HOUR,
    staleTime: MS_IN_AN_HOUR,
    enabled: !!body,
  });
};
