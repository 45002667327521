/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';

import { device } from 'helpers/css/responsive';
import { Loader } from 'components/Loader/Loader';
import { Switch } from 'ui/Switch';

import { thinScrollbarStyle } from 'helpers/css/thin-scrollbar';

import { ActiveCorrectionGroup } from './active-correction-group/ActiveCorrectionGroup';
import { CorrectionHeader } from './components/CorrectionGroupHeader/CorrectionHeader';
import { GradeFormatSwitch } from './components/GradeFormatSwitch';
import { CorrectionGroupsRecap } from './correction-groups-recap/CorrectionGroupsRecap';
import { ClickedQuestionProvider } from './clickedQuestionContext';
import { CollapsableContainer } from './components/CollapsableContainer';
import { CorrectionTaskResponse, CorrectionGroup } from 'types/correction.type';

import { corectionMocks } from './mocks/correctionMocks';
import { useTour } from '@reactour/tour';

type LayoutType = 'LeftToRight' | 'RightToLeft';

interface Props {
  correction: CorrectionTaskResponse | undefined;
  initiallySelectedGroupId?: number;
}

export const Correction: FC<Props> = ({
  correction: unsortedCorrection,
  initiallySelectedGroupId,
}) => {
  const [selectedGroupId, setSelectedGroupId] = useState<number>();
  const [shownCorrection, setShownCorrection] =
    useState<CorrectionTaskResponse>();

  const { isOpen } = useTour();

  const correction = useMemo(() => {
    if (unsortedCorrection === undefined) {
      return undefined;
    }

    const sortedCorrection: CorrectionTaskResponse = {
      ...unsortedCorrection,
      correction_groups: unsortedCorrection.correction_groups.map((group) => {
        if (group.custom_qi) {
          group.correction_questions.sort((q1, q2) => {
            if (q1.question === q2.question) {
              return q1.question.num - q2.question.num;
            }

            return (
              Number(q2.question.collection.split(' ').pop()) -
              Number(q1.question.collection.split(' ').pop())
            );
          });
          return group;
        }

        group.correction_questions.sort(
          (q1, q2) => q1.question.num - q2.question.num
        );
        return group;
      }),
    };

    let dplcaBlocks: CorrectionGroup[] = [];
    let qiBlocks: CorrectionGroup[] = [];

    sortedCorrection.correction_groups.forEach((block: CorrectionGroup) => {
      if (block.group?.num) {
        dplcaBlocks.push(block);
      } else {
        qiBlocks.push(block);
      }
    });

    // Combine the sorted DP and KFP blocks
    let orderedBlocks = dplcaBlocks.sort((a, b) => a.group.num - b.group.num);

    // Add the QI block at the end
    orderedBlocks.push(...qiBlocks);

    return { sortedCorrection, orderedBlocks };
  }, [unsortedCorrection]);

  useEffect(() => {
    setSelectedGroupId(
      initiallySelectedGroupId ?? correction?.orderedBlocks[0].id
    );
  }, [shownCorrection, initiallySelectedGroupId]);

  const selectedGroupData = useMemo(
    () =>
      shownCorrection?.correction_groups.find(
        (group) => group.id === selectedGroupId
      ),
    [selectedGroupId, shownCorrection, isOpen]
  );

  const [layoutType, setLayoutType] = useState<LayoutType>('LeftToRight');
  const toggleLayoutType = () =>
    setLayoutType(layoutType === 'LeftToRight' ? 'RightToLeft' : 'LeftToRight');

  useEffect(() => {
    if (isOpen) {
      setShownCorrection(corectionMocks);
    } else {
      setShownCorrection(correction?.sortedCorrection);
    }
  }, [isOpen, correction?.sortedCorrection]);

  if (shownCorrection)
    return (
      <ClickedQuestionProvider>
        <Container layoutType={layoutType}>
          <Left>
            <LeftHeader>
              <TitleContainer>
                <h1>Correction</h1>
                <GradeFormatSwitch />
              </TitleContainer>

              <SwitchContainer>
                <Switch
                  checked={layoutType === 'RightToLeft'}
                  onChange={toggleLayoutType}
                />
                <small>Gauche - Droite</small>
              </SwitchContainer>
            </LeftHeader>
            {shownCorrection.correction_groups.length > 1 && (
              <CorrectionHeader correctionData={shownCorrection} />
            )}
            <ScrollContainer id="correction-scroll-container">
              <ActiveCorrectionGroup groupData={selectedGroupData} />
            </ScrollContainer>
          </Left>

          <Right>
            <CollapsableContainer>
              {shownCorrection ? (
                <CorrectionGroupsRecap
                  taskCorrection={shownCorrection}
                  selectedGroupId={selectedGroupId}
                  setSelectedGroupId={setSelectedGroupId}
                />
              ) : (
                <Loader />
              )}
            </CollapsableContainer>
          </Right>
        </Container>
      </ClickedQuestionProvider>
    );

  return (
    <LoaderContainer>
      <Loader />
      <h3>Chargement de la correction...</h3>
    </LoaderContainer>
  );
};

const Container = styled.div<{ layoutType: LayoutType }>`
  padding: 8px;
  display: flex;
  gap: 8px;
  justify-content: center;
  flex-direction: ${({ layoutType }) =>
    layoutType === 'LeftToRight' ? 'row' : 'row-reverse'};

  overflow: hidden;
  max-height: 100%;
  ${thinScrollbarStyle}

  @media ${device.largeTable} {
    padding: 32px;
    padding-top: 24px;
    padding-bottom: 8px;
    gap: 32px;
  }
`;

const Left = styled.div`
  flex-grow: 0;
  overflow: hidden;
  flex-basis: 1080px;

  display: flex;
  flex-direction: column;
  gap: 8px;

  @media ${device.largeTable} {
    gap: 24px;
  }
`;

const LeftHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-right: 4px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  padding-right: 4px;
  flex-grow: 1;
`;

const Right = styled.div`
  /* flex-grow: 1; */
  overflow: hidden;
  flex-shrink: 0;
  max-width: 40%;
`;

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  min-height: 500px;
`;
