import styled from 'styled-components/macro';
import { useMemo, useState } from 'react';
import { SearchIcon } from 'ui/icons/Search';
import { device } from 'helpers/css/responsive';
import { useIsUserFree } from 'modules/user-options/hooks/useIsUserPremium';
import { ResearchOptions } from 'http/compass/types';
import { ScrollSpeContainer } from './ScrollSpeContainer';

interface BookSearchBarProps {
  options: ResearchOptions[];
}

export const BookSearchBar: React.FC<BookSearchBarProps> = ({ options }) => {
  const [searchedValue, setSearchedValue] = useState<string>('');
  const [filteredOptions, setFilterOptions] = useState<ResearchOptions[]>(
    options || []
  );

  const isUserFree = useIsUserFree();

  useMemo(() => {
    const filteredData = options.filter((option: ResearchOptions) => {
      if (searchedValue === '') {
        return options;
      } else {
        return option.possibleInput
          .toLowerCase()
          .includes(searchedValue.toLowerCase());
      }
    });
    setFilterOptions(filteredData);
  }, [options, searchedValue]);

  return (
    <>
      <SearchBarContainer>
        <Input
          type="text"
          value={searchedValue}
          onChange={(e) => setSearchedValue(e.target.value)}
          disabled={isUserFree}
          placeholder={'Rechercher'}
        />
        <IconContainer>
          <SearchIcon />
        </IconContainer>
      </SearchBarContainer>
      <ScrollSpeContainer filteredOptions={filteredOptions} />
    </>
  );
};

const SearchBarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary.lighter};
  border-radius: 8px;
  max-width: 100px;
  @media ${device.desktop} {
    max-width: 380px;
  }

  transition: max-width 100ms ease-in-out;
  margin-bottom: 16px;
`;

const Input = styled.input`
  display: flex;
  align-items: center;
  padding: 12px;
  width: 100%;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.primary.lighter};
  border: 1px solid ${({ theme }) => theme.colors.primary.lighter};
  ${({ theme }) => theme.typography.textBody2}
  *:focus {
    outline: none;
  }
  &:focus-within {
    outline: none;
  }
`;

const IconContainer = styled.div`
  min-height: 20px;
  min-width: 20px;
  margin: 0 20px;
`;
