import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import { useGetMultipleQuestionGroups } from 'http/selection/get_question/connector';
import { GetQuestionParams } from 'http/selection/get_question/types';
import { SelectionStatistics } from './SelectionStatistics';

export const useGetDpQiSelectionStats = () => {
  const dpSelectedItems = useSelector(
    (state: RootState) => state.dpTable.selectedItemsInfos
  );

  const qiSelectedItems = useSelector(
    (state: RootState) => state.qiTable.selectedItemsInfos
  );

  const dpInfos: GetQuestionParams[] = useMemo(
    () =>
      dpSelectedItems.map((dp) => ({
        group_id: dp.id,
      })),
    [dpSelectedItems]
  );

  const selectedDPsQuestions = useGetMultipleQuestionGroups(dpInfos);
  const allQueriesFinished = selectedDPsQuestions.every(
    (query) => query.isLoading === false
  );

  const selectionStats = useMemo(() => {
    if (!allQueriesFinished) return null;

    const newStats = new SelectionStatistics();

    qiSelectedItems.forEach((question) => {
      newStats.addSpecialty(question.specialty1);
      newStats.addSpecialty(question.specialty2);
      newStats.addItems(question.item);
      newStats.addTags(question.categories);
      newStats.addKnowledges(question.knowledge?.knowledge);
    });

    selectedDPsQuestions.forEach((query) => {
      const dpQuestions = query.data;
      if (dpQuestions) {
        dpQuestions.forEach((question) => {
          newStats.addSpecialty(question.specialty1);
          newStats.addSpecialty(question.specialty2);
          newStats.addItems(question.knowledge?.knowledge);
          newStats.addTags(question.categories);
          newStats.addTypes(question.type);
          newStats.addKnowledges(question.knowledge?.knowledge);
        });
      }
    });

    return newStats;
  }, [qiSelectedItems, selectedDPsQuestions, allQueriesFinished]);

  return selectionStats || new SelectionStatistics();
};
